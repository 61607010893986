<template>
  <v-row justify="center">
    <v-dialog
      :value=true
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Vehicle: {{ selectedVehicle.reg }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="$store.state.workers"
                  label="Chargehand"
                  v-model="chargehand"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="$store.state.workers"
                  label="Worker 1"
                  v-model="worker2"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="$store.state.workers"
                  label="Worker 2"
                  v-model="worker3"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="$store.state.workers"
                  label="Worker 3"
                  v-model="worker4"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="orange darken-1"
            @click="saveChanges"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Save
          </v-btn>
          <v-btn
            small
            color="blue-grey lighten-4"
            @click="closeSetDefaultWorkers"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Abandon Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SetDefaultWorkers',
  computed: {
    selectedVehicle() {
      return this.$store.getters.selectedVehicle;
    },
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      chargehand: 0,
      worker2: 0,
      worker3: 0,
      worker4: 0,
    };
  },
  methods: {
    setInitialValues() {
      if (this.selectedVehicle.chargehand) this.chargehand = this.selectedVehicle.chargehand;
      if (this.selectedVehicle.worker2) this.worker2 = this.selectedVehicle.worker2;
      if (this.selectedVehicle.worker3) this.worker3 = this.selectedVehicle.worker3;
      if (this.selectedVehicle.worker4) this.worker4 = this.selectedVehicle.worker4;
    },
    closeSetDefaultWorkers() {
      this.$emit('closeSetDefaultWorkers');
    },
    saveChanges() {
      const postData = {
        vehicle: this.selectedVehicle.id,
        1: this.chargehand,
        2: this.worker2,
        3: this.worker3,
        4: this.worker4,
      };
      axios.post(`/vehiclesWorkers/update.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshVehicles');
          this.$emit('closeSetDefaultWorkers');
        });
    },
  },
  mounted() {
    this.setInitialValues();
  },
};
</script>
