<template>
  <v-row justify="center">
    <v-dialog
      :value=true
      persistent
      max-width="600px"
    >
      <v-card>
        <p class="text-center pt-8">
          <span class="text-h5">Vehicle: {{ selectedVehicle.reg }}</span>
        </p>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <sorted-range-date-picker
                  :maintenanceRecord="defaultMaintenanceRecord"
                  @dateChanged="setDates"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <time-start-end-picker
                  v-if="dates"
                  @startTimePicked="startTimePicked"
                  @endTimePicked="endTimePicked"
                />
                <v-textarea
                  v-if="dates"
                  v-model="description"
                  placeholder="description"
                  solo
                  rows="3"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="dates" class="pt-0">
                <v-card-subtitle
                 v-if="dates"
                 class="pb-3 pt-0"
                >
                  {{ selectedVehicle.reg }} will be out of service
                  from: {{ dates[0] | prettyDate }} {{ timeStart }}
                  to: {{ dates[1] | prettyDate }} {{ timeEnd }}
                  for: {{ description }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="pb-5 pr-4">
          <v-col class="pt-0">
            <v-card-actions class="float-right">
              <v-btn
                @click="saveChanges"
                small
                color="orange darken-1"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Save
              </v-btn>
              <v-btn
                small
                color="blue-grey lighten-4"
                @click="closeSetMaintenance"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Abandon Changes
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="600"
      v-model="showWarning"
    >
      <v-card class="pt-6 pb-6">
        <v-card-text>
          {{ warning }} job(s) are booked for this vehicle on the dates you have chosen
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            small
            @click="dismissWarning"
          >
            Dismiss warning
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TimeStartEndPicker from '@/components/Tools/TimeStartEndPicker.vue';
import SortedRangeDatePicker from '@/components/Tools/SortedRangeDatePicker.vue';
import axios from '../../axios';

export default {
  name: 'SetMaintenance',
  components: {
    TimeStartEndPicker,
    SortedRangeDatePicker,
  },
  data() {
    return {
      dates: null,
      description: '',
      timeStart: '08:00',
      timeEnd: '18:00',
      defaultMaintenanceRecord: {
        start: new Date(),
        end: new Date(),
      },
      showWarning: false,
      warning: 0,
    };
  },
  computed: {
    selectedVehicle() {
      return this.$store.getters.selectedVehicle;
    },
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    dates() {
      if (this.dates[0]) {
        const postData = {};
        postData.dates = this.dates;
        postData.vehicle = this.selectedVehicle;
        axios.post(`/vehicleUnavailableDates/checkNoJobs.json?token=${this.token}`, postData)
          .then((response) => {
            if (response.data !== 0) {
              this.showWarning = true;
              this.warning = response.data;
            }
          });
      }
    },
  },
  methods: {
    dismissWarning() {
      this.warning = null;
      this.showWarning = false;
    },
    startTimePicked(value) {
      this.timeStart = value;
    },
    endTimePicked(value) {
      this.timeEnd = value;
    },
    setDates(value) {
      const sortedDates = [];
      if (value[0] && !value[1]) {
        sortedDates.push(value[0]);
        sortedDates.push(value[0]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() < new Date(value[1]).getTime()) {
        sortedDates.push(value[0]);
        sortedDates.push(value[1]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() > new Date(value[1]).getTime()) {
        sortedDates.push(value[1]);
        sortedDates.push(value[0]);
      }
      this.dates = sortedDates;
    },
    saveChanges() {
      const postData = {};
      postData.vehicle = this.selectedVehicle.id;
      postData.dates = this.dates;
      postData.timeStart = this.timeStart;
      postData.timeEnd = this.timeEnd;
      postData.description = this.description;
      postData.timeOffset = new Date().getTimezoneOffset();
      axios.post(`/vehicleUnavailableDates/addServiceDates.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshVehicles');
          this.$emit('closeSetMaintenance');
        });
    },
    closeSetMaintenance() {
      this.$emit('closeSetMaintenance');
    },
  },
};
</script>
