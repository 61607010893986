<template>
  <v-row justify="center">
    <v-dialog
      :value=true
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Vehicle: {{ selectedVehicle.reg }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="$store.state.departments"
                  label="Department"
                  v-model="department"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="orange darken-1"
            @click="saveChanges"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Save
          </v-btn>
          <v-btn
            small
            color="blue-grey lighten-4"
            @click="closeSetDefaultWorkers"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Abandon Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SetDepartment',
  computed: {
    selectedVehicle() {
      return this.$store.getters.selectedVehicle;
    },
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      department: 0,
    };
  },
  methods: {
    setInitialValues() {
      this.department = this.selectedVehicle.department;
    },
    closeSetDefaultWorkers() {
      this.$emit('closeSetDepartment');
    },
    saveChanges() {
      const postData = {
        vehicle: this.selectedVehicle.id,
        department: this.department,
      };
      axios.post(`/vehicles/setDepartment.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshVehicles');
          this.$emit('closeSetDepartment');
        });
    },
  },
  mounted() {
    this.setInitialValues();
  },
};
</script>
