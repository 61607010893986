<template>
  <div>
    <v-card
      class="ml-3 mt-3"
      max-width="700"
      outlined
      v-if="selectedVehicleId > 0"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Vehicle
            <span class="float-right">
              <v-icon
                class="action-icon"
                @click="$store.commit('selectVehicle', 0)"
              >
                mdi-close-box
              </v-icon>
            </span>
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ selectedVehicle.reg }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="selectedVehicle.department">
            Department: {{ selectedVehicle.department }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="selectedVehicle.chargehand">
            Default chargehand: {{ selectedVehicle.chargehandName }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="selectedVehicle.worker2">
            Default worker 1: {{ selectedVehicle.worker2Name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="selectedVehicle.worker3">
            Default worker 2: {{ selectedVehicle.worker3Name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="selectedVehicle.worker4">
            Default worker 3: {{ selectedVehicle.worker4Name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <div v-if="selectedVehicle.unavailableDates">
        <p class="pl-4 mb-0">Current scheduled maintenance:</p>
        <v-list-item
          style="min-height: 5px;"
          v-for="(unavailable, index) in selectedVehicle.unavailableDates"
          :key="index">
          <v-list-item-content class="pb-2 pt-2">
            <v-list-item-subtitle>
              {{ unavailable.start | prettyDateTime }} to {{ unavailable.end | prettyDateTime }}
              &nbsp;
              <v-icon
                class="action-icon"
                small
                color="orange darken-2"
                @click="editMaintenance = unavailable"
              >
                mdi-pencil
              </v-icon>
              &nbsp;
              <v-icon
                class="action-icon"
                small
                color="red darken-1"
                @click="removeMaintenance(unavailable.id)"
              >
                mdi-delete
              </v-icon>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-card-actions>
        <v-btn
          outlined
          text
          @click="updateDefaultWorkers = true"
        >
          Set default workers
        </v-btn>
        <v-btn
          outlined
          text
          @click="addMaintenance = true"
        >
          Add scheduled maintenance
        </v-btn>
        <v-btn
          outlined
          text
          @click="setDepartment = true"
        >
          Set department
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      class="ml-3 mt-3"
      max-width="600"
      outlined
      v-else
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Vehicle
          </div>
          <v-col>
            <v-list-item-title class="text-h5 mb-1">
              <v-icon color="orange lighten-2">
                mdi-arrow-left-thick
              </v-icon>
              Click on a vehicle to select
            </v-list-item-title>
          </v-col>
          <v-col>
            <router-link to="/planning/order-vehicles">
              <v-btn small>Set department / order</v-btn>
            </router-link>
          </v-col>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-sheet
      class="pt-3 pl-3 pr-3"
      width="100%"
    >
      <v-calendar
        type="month"
        v-model="month"
        :events="maintenanceRecords"
        @click:event="showEvent"
        ref="calendar"
      >
      </v-calendar>
      <v-toolbar
        flat
      >
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
        >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>

    <set-default-workers
      v-if="updateDefaultWorkers"
      @closeSetDefaultWorkers="updateDefaultWorkers = false"
    />

    <set-maintenance
      v-if="addMaintenance"
      @closeSetMaintenance="addMaintenance = false"
    />

    <edit-maintenance
      v-if="editMaintenance"
      :maintenanceRecord="editMaintenance"
      @closeEditMaintenance="editMaintenance = false"
    />

    <set-department
      v-if="setDepartment"
      @closeSetDepartment="setDepartment = false"
    />
  </div>
</template>

<script>
import SetDefaultWorkers from '@/components/Vehicles/SetDefaultWorkers.vue';
import SetDepartment from '@/components/Vehicles/SetDepartment.vue';
import SetMaintenance from '@/components/Vehicles/SetMaintenance.vue';
import EditMaintenance from '@/components/Vehicles/EditMaintenance.vue';
import axios from '../../axios';

export default {
  name: 'Vehicles',
  components: {
    SetDefaultWorkers,
    SetDepartment,
    SetMaintenance,
    EditMaintenance,
  },
  computed: {
    selectedVehicleId() {
      return this.$store.state.selectedVehicleId;
    },
    selectedVehicle() {
      return this.$store.getters.selectedVehicle;
    },
    token() {
      return this.$store.state.token;
    },
    vehicles() {
      return this.$store.state.vehicles;
    },
  },
  data() {
    return {
      updateDefaultWorkers: false,
      addMaintenance: false,
      editMaintenance: false,
      setDepartment: false,
      maintenanceRecords: [],
      month: new Date(),
    };
  },
  watch: {
    vehicles() {
      this.updateMaintenanceList();
    },
  },
  methods: {
    setToday() {
      this.month = new Date();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      console.log(event);
      this.$store.commit('selectVehicle', event.vehicleId);
      this.editMaintenance = {
        description: event.name,
        end: event.end,
        id: event.id,
        start: event.start,
      };
      nativeEvent.stopPropagation();
    },
    updateMaintenanceList() {
      const maintenanceRecords = [];
      for (let i = 0; this.vehicles.length > i; i += 1) {
        if (this.vehicles[i].unavailableDates) {
          for (let x = 0; this.vehicles[i].unavailableDates.length > x; x += 1) {
            maintenanceRecords.push({
              name: `${this.vehicles[i].reg}: ${this.vehicles[i].unavailableDates[x].description}`,
              start: new Date(this.vehicles[i].unavailableDates[x].start),
              end: new Date(this.vehicles[i].unavailableDates[x].end),
              id: this.vehicles[i].unavailableDates[x].id,
              vehicleId: this.vehicles[i].id,
              color: 'red darken-2',
            });
          }
        }
      }
      this.maintenanceRecords = maintenanceRecords;
    },
    removeMaintenance(id) {
      axios.post(`/vehicleUnavailableDates/remove.json?token=${this.token}`, id)
        .then(() => {
          this.$store.dispatch('refreshVehicles');
        });
    },
  },
  mounted() {
    this.updateMaintenanceList();
    this.$refs.calendar.checkChange();
  },
};
</script>

<style lang="scss">
  .action-icon {
    position:relative;
    top: -1px;
    cursor: pointer;
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
</style>
